import { acceptHMRUpdate, defineStore } from 'pinia'

import { useInfluencersStore } from './influencers'
import { useUserBandStore } from './userBand'

import { useProvideCoreFetch } from '~/composables/useProvideCoreFetch'

import { configToQs } from '~/helpers/configToQs'
import { submissionIsRead } from '~/helpers/submission/isRead'

import type { BandDashboardStatusFilter } from '~/enums/BandDashboardStatusFilter'
import type { PaginatedApiResponse } from '~/types/PaginatedApiResponse'
import type { ModdedAnswer, SubmissionV2 } from '~/types/submissionV2'

const state = () => ({
  filteredSubmissions: [] as SubmissionV2[],
  filteredSubmissionsTotal: 0,
  filter: {
    offset: 0,
    limit: 50,
  },
  selectedSubmissionIds: [] as SubmissionV2['id'][],
})

export type SubmissionsState = ReturnType<typeof state>

export const useSubmissionsStore = defineStore('submissions', () => {
  const { coreFetch } = useProvideCoreFetch()
  const { $pinia } = useNuxtApp()
  const userBandStore = useUserBandStore($pinia)
  const influencersStore = useInfluencersStore($pinia)

  const filteredSubmissions = ref<SubmissionV2[]>([])
  const filteredSubmissionsTotal = ref(0)
  const filter = ref({ offset: 0, limit: 50 })
  const selectedSubmissionIds = ref<SubmissionV2['id'][]>([])

  function SET_FILTER({ offset, limit }: SubmissionsState['filter']) {
    filter.value.offset = offset
    filter.value.limit = limit
  }

  function SET_TOTAL_SUBMISSION_AMOUNT_FOR_CURRENT_SET(total: number) {
    filteredSubmissionsTotal.value = total
  }

  function SET_FILTERED_SUBMISSIONS(submissions: SubmissionV2[]) {
    filteredSubmissions.value.splice(
      0,
      filteredSubmissions.value.length,
      ...submissions,
    )
  }

  function SET_SELECTED_SUBMISSIONS_IDS(submissionIds: SubmissionV2['id'][]) {
    selectedSubmissionIds.value = submissionIds
  }

  function UPDATE_BY_ID(
    submission: Partial<SubmissionV2> & { id: SubmissionV2['id'] },
  ) {
    const index = filteredSubmissions.value.findIndex(
      (e) => e.id === submission.id,
    )

    if (index === -1) return

    const newSubmission = {
      ...filteredSubmissions.value[index],
      ...submission,
    }

    filteredSubmissions.value.splice(index, 1, newSubmission)
  }

  async function FETCH({
    offset = 0,
    limit = 20,
    trackId = undefined,
    campaignId = undefined,
    statuses = [],
  }: {
    offset: number
    limit: number
    trackId?: number
    campaignId?: number
    statuses: BandDashboardStatusFilter[] | undefined
  }) {
    const opportunities = statuses.length ? statuses.join(',') : undefined
    const filter: SubmissionsState['filter'] = { offset, limit }
    SET_FILTER(filter)

    try {
      const response = await coreFetch.$get<PaginatedApiResponse<SubmissionV2>>(
        `/submission/submissions/?${configToQs({
          band_id: userBandStore.id,
          offset,
          limit,
          opportunities,
          track_ids: trackId,
          campaign_ids: campaignId,
        })}`,
      )

      SET_FILTERED_SUBMISSIONS(response.results)
      SET_TOTAL_SUBMISSION_AMOUNT_FOR_CURRENT_SET(response.count)

      // This can actually be fully async ! No need to wait for this :>
      const influencerIds = response.results.map(
        (e: SubmissionV2) => e.influencer,
      )
      influencersStore.FETCH_SET(influencerIds, true)

      return response.results
    } catch (_) {
      return []
    }
  }

  async function UPDATE_SUBMISSION_WITH_ID(submissionId: number) {
    try {
      const submission = await coreFetch.$get<SubmissionV2>(
        `/submission/submissions/${submissionId}/?${configToQs({
          band_id: userBandStore.id,
        })}`,
      )

      UPDATE_BY_ID(submission)
    } catch (_) {
      // mute error
    }
  }

  async function RATE_SUBMISSION({
    answerId,
    rating,
    criteriaIds,
  }: {
    answerId: number
    rating: number
    criteriaIds: number[]
  }) {
    const submission = filteredSubmissions.value.find(
      (s) => s.answer?.id === answerId,
    )
    if (!submission)
      throw new Error('Submission not found for answer id: ' + answerId)

    const postData = {
      answer: answerId,
      score: rating,
      criteria: criteriaIds,
    }
    await coreFetch.$post('/answer/rate/', postData)

    const submissionUpdate: Partial<SubmissionV2> & {
      id: SubmissionV2['id']
    } = {
      id: submission.id,
      answer: {
        ...(submission.answer as ModdedAnswer),
        rating,
      },
    }

    UPDATE_BY_ID(submissionUpdate)
  }

  async function MARK_AS_READ({ submissionId }: { submissionId: number }) {
    const submission = filteredSubmissions.value.find(
      ({ id }) => id === submissionId,
    )

    // Skip if already read
    if (submission && submissionIsRead(submission)) return

    const updatedSubmission = await coreFetch.$get<SubmissionV2>(
      `/submission/read/${submissionId}/`,
    )
    UPDATE_BY_ID(updatedSubmission)
  }

  return {
    // state
    filteredSubmissions,
    filteredSubmissionsTotal,
    filter,
    selectedSubmissionIds,

    // actions
    SET_FILTER,
    SET_TOTAL_SUBMISSION_AMOUNT_FOR_CURRENT_SET,
    SET_FILTERED_SUBMISSIONS,
    SET_SELECTED_SUBMISSIONS_IDS,
    UPDATE_BY_ID,
    FETCH,
    UPDATE_SUBMISSION_WITH_ID,
    RATE_SUBMISSION,
    MARK_AS_READ,
  }
})

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useSubmissionsStore, import.meta.hot))
